import router from "./router";
import CONFIG from "./config";
import { Toast, Dialog } from "vant";
import Vue from "vue";
import axios from "axios";

Vue.use(Toast);
Vue.use(Dialog);

// 梅占理
const shulanBaseUrl =
  process.env.NODE_ENV === "production"
    ? CONFIG.group.apiUrl
    : process.env.NODE_ENV === "testing"
      ? CONFIG.group.testApiUrl
      : process.env.NODE_ENV === "development"
        ? "/localBaseUrl"
        : CONFIG.group.apiUrl;
// const shulanBaseUrl = process.env.NODE_ENV === 'production' ? CONFIG.group.apiUrl : CONFIG.group.testApiUrl

// 华卓
const wowjoyBaseUrl =
  process.env.NODE_ENV === "production"
    ? CONFIG.wowjoy.apiUrl
    : process.env.NODE_ENV === "testing"
      ? CONFIG.wowjoy.testApiUrl
      : process.env.NODE_ENV === "development"
        ? CONFIG.wowjoy.testApiUrl
        : CONFIG.wowjoy.apiUrl;

// const hzkyBaseUrl = '/hzkyapi'
// const picUpload = '/picupload'

// 配置超时时间
axios.defaults.timeout = 60000;

// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做一些操作
    if (localStorage.getItem("Token")) {
      config.headers["Authorization"] = localStorage.getItem("Token");
    }
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["X-Requested-With"] = "XMLHTTPRequest";

    return config;
  },
  (error) => {
    // 请求错误时做些事
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做些事
    let charCode = response.status.toString().charAt(0);
    if (charCode === "2") {
      return response;
    } else {
      router.push({ path: "/error", query: { errCode: 999 } });
    }
  },
  (error) => {
    // 请求错误时做些事
    switch (error.response.status) {
      case 401:
        console.log(_getQueryVariable("platform"), "router");
        switch (_getQueryVariable("platform")) {
          case "miniprogram": // 小程序
            wx.miniProgram.navigateTo({
              url: `/pages/common-package/login/login`,
            });
            break;
          case "app": // ios android
            setTimeout(() => {
              Toast.fail({
                message: "登录已过期",
              });
            }, 600);
            const params = {
              test: "",
            };
            // eslint-disable-next-line no-undef
            goLogin.postMessage(JSON.stringify(params));
            break;
          default:
            setTimeout(() => {
              Toast.fail(
                {
                  message: "你没有登录",
                },
                300
              );
            });
        }
        break;
      case 404:
        router.push({
          path: "/error",
          query: { errCode: 404, platform: _getQueryVariable("platform") },
        });
        break;
      case 500:
        router.push({
          path: "/error",
          query: { errCode: 500, platform: _getQueryVariable("platform") },
        });
        break;
      default:
        router.push({
          path: "/error",
          query: { errCode: 999, platform: _getQueryVariable("platform") },
        });
    }
    return Promise.reject(error);
  }
);

export default {
  // GET 请求（集团）
  get({ url, params = {}, message = "请稍等", loading = true }) {
    debugger
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    let finalUrl;
    if (url.indexOf("https://") > -1 || url.indexOf("http://") > -1) {
      finalUrl = url;
    } else {
      // 目前小程序的接口使用还需要代理，所以这里自行拼接了小程序的正式或测试接口地址
      finalUrl =
        _isNeedProxy(url) > -1
          ? `/shulanwebapi${url}`
          : `${process.env.NODE_ENV === "production" ? "/shulanwebapi" : ""
          }${url}`;
      finalUrl = shulanBaseUrl + finalUrl;
    }
    return axios
      .get(finalUrl, {
        params,
      })
      .then((res) => {

        if (loading) {
          Toast.clear();
        }
        if (res.status === 200) {
          return Promise.resolve(res);
        } else {
          Dialog.confirm({
            title: "提醒",
            message: "请求失败，是否重试？",
          })
            .then(() => {
              this.get({ url, params, message, loading });
            })
            .catch(() => {
              router.go(-1);
            });
        }
      })
      .catch((err) => {

        if (loading) {
          Toast.clear();
        }
        console.log(`${finalUrl} - ${params}`, err);
      });
  },
  // POST 请求（集团）
  post({ url, params = {}, message = "请稍等", loading = true }) {
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    let finalUrl;
    if (url.indexOf("https://") > -1 || url.indexOf("http://") > -1) {
      finalUrl = url;
    } else {
      // 目前小程序的接口使用还需要代理，所以这里自行拼接了小程序的正式或测试接口地址
      finalUrl =
        _isNeedProxy(url) > -1
          ? `/shulanwebapi${url}`
          : `${process.env.NODE_ENV === "production" ? "/shulanh5api" : ""
          }${url}`;
      finalUrl = shulanBaseUrl + finalUrl;
    }
    return axios
      .post(finalUrl, params)
      .then((res) => {
        if (loading) {
          Toast.clear();
        }
        if (res.status === 200 || res.status === 201) {
          return Promise.resolve(res);
        } else {
          Dialog.confirm({
            title: "提醒",
            message: "请求失败，是否重试？",
          })
            .then(() => {
              this.post({ url, params, message, loading });
            })
            .catch(() => {
              router.go(-1);
            });
        }
      })
      .catch((err) => {
        if (loading) {
          Toast.clear();
        }
        console.log(`${finalUrl} - ${JSON.stringify(params)}`, err);
      });
  },
  // DELETE 请求
  delete(url, params = {}, message = "请稍等", loading = true) {
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    let finalUrl;
    if (url.indexOf("https://") > -1 || url.indexOf("http://") > -1) {
      finalUrl = url;
    } else {
      // 目前小程序的接口使用还需要代理，所以这里自行拼接了小程序的正式或测试接口地址
      finalUrl =
        _isNeedProxy(url) > -1
          ? `/shulanwebapi${url}`
          : `${process.env.NODE_ENV === "production" ? "/shulanh5api" : ""
          }${url}`;
      finalUrl = shulanBaseUrl + finalUrl;
    }
    return axios
      .delete(`${shulanBaseUrl}${finalUrl}`, {
        params,
      })
      .then((res) => {
        Toast.clear();
        if (res.status === 200) {
          return Promise.resolve(res);
        } else {
          Dialog.confirm({
            title: "提醒",
            message: "请求失败，是否重试？",
          })
            .then(() => {
              this.delete({ url, params, message, loading });
            })
            .catch(() => {
              router.go(-1);
            });
        }
      })
      .catch((err) => {
        Toast.clear();
        console.log(`${url} - ${params}`, err);
      });
  },
  // 华卓GET 请求
  wowjoyGet({ url, params = {}, message = "请稍等", loading = true }) {
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    return axios
      .get(wowjoyBaseUrl + url, {
        params,
      })
      .then((res) => {
        if (loading) {
          Toast.clear();
        }
        if (res.status === 200 && res.data.state === 200) {
          return Promise.resolve(res);
        } else {
          Dialog.confirm({
            title: "提醒",
            message: "请求失败，是否重试？",
          })
            .then(() => {
              this.wowjoyGet({ url, params, message, loading });
            })
            .catch(() => {
              router.go(-1);
            });
        }
      })
      .catch((err) => {
        console.log(`2:${wowjoyBaseUrl}${url}`);
        Toast.clear();
        console.log(`${url} - ${params}`, err);
      });
  },
  // 华卓POST 请求
  wowjoyPost({ url, params = {}, message = "请稍等", loading = true }) {
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    return axios
      .post(`${wowjoyBaseUrl}${url}`, params)
      .then((res) => {
        if (loading) {
          Toast.clear();
        }
        let startChar = res.status.toString().charAt(0);
        if (startChar === "2" && res.data.state === 200) {
          return Promise.resolve(res);
        } else {
          Dialog.confirm({
            title: "提醒",
            message: "请求失败，是否重试？",
          })
            .then(() => {
              this.wowjoyPost({ url, params, message, loading });
            })
            .catch(() => {
              router.go(-1);
            });
        }
      })
      .catch((err) => {
        Toast.clear();
        console.log(`${url} - ${JSON.stringify(params)}`, err);
      });
  },


  // sho-h5
  shoh5Post({ url, params = {}, message = "请稍等", loading = true }) {
    if (loading) {
      Toast.loading({
        message,
        duration: 0,
      });
    }
    return axios
      .post(`https://sho-h5.shulan.com/v2${url}`, params)
      .then((res) => {
        if (loading) {
          Toast.clear();
        }
        let startChar = res.status.toString().charAt(0);
        if (startChar === "2" && res.data.state === 200) {
          return Promise.resolve(res);
        } else {
          //   Dialog.confirm({
          //     title: "提醒",
          //     message: "请求失败，是否重试？",
          //   })
          //     .then(() => {
          //       this.wowjoyPost({ url, params, message, loading });
          //     })
          //     .catch(() => {
          //       router.go(-1);
          //     });
        }
      })
      .catch((err) => {
        Toast.clear();
        console.log(`${url} - ${JSON.stringify(params)}`, err);
      });
  },

};

// 代理到小程序接口地址
function _isNeedProxy(url) {
  const miniprogramApi = [
    "/patients",
    "/visitRecord",
    "/prescription",
    "/file",
    "/patient",
    "/check",
    "/sms",
    "/inhospital",
    "/courses",
    "/author",
    "/courses/buy",
    "/payment/course",
    "/webAuth/login",
  ];
  let match = -1;
  miniprogramApi.map((item, index) => {
    if (url.indexOf(item) > -1) {
      match = index;
    }
  });
  return match;
}

// 获取url参数
function _getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
