export default {
    // 资源地址 如图片
    'qnImgUrl': 'https://sho-static.shulan.com',
    // h5网页公共地址
    'groupWebUrl': {
        // api地址
        'url': 'https://h5.shulan.com',
        // api地址
        'testUrl': 'https://test-h5.shulan.com',
        // 本地开发地址
        'devUrl': 'http://192.168.10.105:8001'
    },
    // API接口集团地址（梅占理）
    'group': {
        // api地址
        'apiUrl': 'https://h5.shulan.com/v2',
        // api地址
        'testApiUrl': 'https://test-h5.shulan.com/v2',
        // 本地开发地址
        'devApiUrl': 'http://192.168.10.105:8002/v2'
    },
    // API接口华卓地址
    'wowjoy': {
        'apiUrl': 'https://shulan-hospital.shulan.com',
        'testApiUrl': 'https://test-shulan-hospital.shulan.com',
        'sourceImgUrl': 'https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=',
        'testSourceImgUrl': 'https://test-cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName='
    }
}
