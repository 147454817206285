/**
 * [手机号验证]
 * @param  {[type]} phone [手机号]
 * @return {[type]}          [description]
 */
 const checkPhone = (phone) => {
    var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    return reg.test(phone);
};

module.exports = {
    checkPhone
}